import React from "react";
import EmpresaService from "../../services/EmpresaService";
import AutoCompleteAsyncPersist from "./autocomplete/AutoCompleteAsyncPersist";

const EmpresaComplete = (props) => {
  const handleFetchAPI = async (valueSearch) => {
    const response = await EmpresaService.pesquisa(valueSearch, 0);
    return response.content;
  };
 
  return (
    <AutoCompleteAsyncPersist
      name="autoEmpresa" 
      label="Empresa"
      itemLabel={"nome"}
      fullWidth
      onFetchAPI={handleFetchAPI}
      {...props}
    />
  );
};

export default EmpresaComplete;
