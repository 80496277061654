let _alertRef;

function setAlertRef(rootRef) {
  _alertRef = rootRef;
}

function success(titulo, mensagem) {
  _alertRef.showSuccess(titulo, mensagem);
}

function info(titulo, mensagem) {
  _alertRef.showInfo(titulo, mensagem);
}

function error(titulo, mensagem) {
  _alertRef.showError(titulo, mensagem);
}

export default { success, info, error, setAlertRef };
