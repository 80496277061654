import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import React from "react";
import { Check } from "react-feather";

const CadastrarButton = ({
  onClick = () => {},
  title = "Cadastrar",
  type = "submit",
  style,
  icon = <Check />,
  disabled = false,
  carregando = false
}) => {
  return (
    <>
      <LoadingButton
        style={style}
        type={type}
        loading={carregando}
        variant="contained"
        color="primary"
        startIcon={icon}
        onClick={() => onClick()}
        disabled={disabled}
      >
        {title}
      </LoadingButton>
    </>
  );
};

export default CadastrarButton;
