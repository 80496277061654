import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.snow.css";
import styled from "@emotion/styled";
import PropTypes from "prop-types";

const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 200px;
  }
`;

const TextArea = (props) => {
  return (
    <QuillWrapper>
      <ReactQuill
        {...props}
        theme="snow"
        modules={{ toolbar: props.showToolbar }}
        value={props.value}
        onChange={props.onChange}
        placeholder="Escreva sua mensagem aqui.."
      />
    </QuillWrapper>
  );
};

TextArea.propTypes = {
  showToolbar: PropTypes.bool,
};

TextArea.defaultProps = {
  showToolbar: true,
};

export default TextArea;
