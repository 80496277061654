import { Snackbar } from "@mui/material";
import React, { Component } from "react";
import MuiAlert from '@mui/material/Alert';

export default class AlertComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visivel: false,
      title: "",
      detail: "",
      type: "",
    };
  }

  showSuccess(title = "Sucesso", detail) {
    this.setState({
      title: title,
      detail: detail,
      visivel: true,
      type: "success",
    });
  }

  showError(title = "Error", detail) {
    this.setState({
      title: title,
      detail: detail,
      visivel: true,
      type: "error",
    });
  }

  showInfo(title = "Info", detail) {
    this.setState({
      title: title,
      detail: detail,
      visivel: true,
      type: "info",
    });
  }

  render() {
    return (
      <>
        {" "}
        {this.state.visivel && (
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.visivel}
            autoHideDuration={10000}
            onClose={() => this.setState({ visivel: false })}
          >
            <MuiAlert
              onClose={() => this.setState({ visivel: false })}
              severity={this.state.type}
              elevation={5}
            >
              {this.state.detail}
            </MuiAlert>
          </Snackbar>
        )}{" "}
      </>
    );
  }
}
