import { createTheme as createMuiTheme } from "@mui/material/styles";
import breakpoints from "./breakpoints";
import overrides from "./overrides";
import props from "./props";
import typography from "./typography";
import variants from "./variants";

const createTheme = (name) => {
  let themeConfig = variants.find((variant) => variant.name === name);

  if (!themeConfig) {
    console.warn(new Error(`The theme ${name} is not valid`));
    themeConfig = variants[0];
  }

  return createMuiTheme({
    components: {
      MuiTextField: {
        defaultProps: {
          variant: 'standard',
        },
      MuiLink: {
        defaultProps: {
          underline: 'hover',
        },
      },
      },
    },
    spacing: 4,
    breakpoints: breakpoints,
    overrides: overrides,
    props: props,
    typography: typography,
    
   // shadows: shadows,
    palette: themeConfig.palette,
  }, {
    name: themeConfig.name,
    header: themeConfig.header,
    footer: themeConfig.footer,
    sidebar: themeConfig.sidebar,
  });
};

export default createTheme;
