import React, { useEffect, useState } from "react";
import { TextField as MuiTextField } from "@mui/material";

const TextField = (props) => {
  const [isShrink, setIsShrink] = useState(false);

  useEffect(() => {
    setIsShrink(props.value);
  }, [props.value]);

  return (
    <MuiTextField
      variant="outlined"
      InputLabelProps={{ shrink: isShrink }}
      {...props}
      onFocus={(e) => {
        setIsShrink(true);
        if (props.onFocus) {
          props.onFocus(e);
        }
      }}
      onBlur={(e) => {
        if (!props.value) {
          setIsShrink(false);
        }
        if (props.onBlur) {
          props.onBlur(e);
        }
      }}
    />
  );
};

export default TextField;
