import React from "react";
import {
  PlusSquare,
  ShoppingCart,
  Users
} from "react-feather";
import { AiOutlineBank } from "react-icons/ai";
import { BiMessageAltCheck } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";

import { HiOutlineDocumentReport } from "react-icons/hi";
import {
  RiDashboardLine,
  RiLockPasswordLine
} from "react-icons/ri";
import { TiMessages } from "react-icons/ti";
import async from "../components/Async";
const TokenMFA = async(() =>
  import("../pages/auth/TokenMFA")
);
const RelatorioParcelas = async(() => import("../pages/relatorio/parcelas/RelatorioParcelas"));
const RelatorioTabelaComissao = async(() => import("../pages/relatorio/tabelaComissao/RelatorioTabelaComissao"));
const AcoesLote = async(() => import("../pages/acoeslote/AcoesLote"));
const Produto = async(() => import("../pages/produto/Produto"));
const RelatorioInadimplencia = async(() => import("../pages/relatorio/inadimplencia/RelatorioInadimplencia"));
const RelatorioComissao = async(() => import("../pages/relatorio/comissao/RelatorioComissao"));
const Leads = async(() => import("../pages/relatorio/leads"));
const Simulacao = async(() => import("../pages/simulacao/Simulacao"));
const BrandingConsignado = async(() => import("../pages/cadastro/empresa/BrandingConsignado"));
const ImportacaoArquivoRetorno = async(() => import("../pages/arquivoretorno/ImportacaoArquivoRetorno"));
const GerarArquivoCartao = async(() => import("../pages/arquivocartao/GerarArquivoCartao"));
const RelatorioProposta = async(() => import("../pages/relatorio/proposta/RelatorioProposta"));
const PesquisarCliente = async(() => import("../pages/pessoa/PesquisarCliente"));
const PesquisarUsuario = async(() => import("../pages/pessoausuario/PesquisarCliente"));
const PontoVenda = async(() => import("../pages/pontoVenda/PontoVenda"));
const Antifraude = async(() => import("../pages/antifraude/Antifraude"));
const Motivo = async(() => import("../pages/motivo/Motivo"));
const Split = async(() => import("../pages/split/Split"));
const ManterCliente = async(() => import("../pages/pessoa/ManterCliente"));
const ManterUsuario = async(() => import("../pages/pessoausuario/ManterCliente"));
const Proposta = async(() => import("../pages/proposta/Proposta"));
const ManterProposta = async(() => import("../pages/proposta/ManterProposta"));
const Convenio = async(() => import("../pages/convenio/Convenio"));
const Secretaria = async(() => import("../pages/secretaria/Secretaria"));
const Tabela = async(() => import("../pages/tabela/Tabela"));
const TabelaRegra = async(() => import("../pages/tabelaRegra/TabelaRegra"));
const Promotora = async(() => import("../pages/promotora/Promotora"));
const Mensagem = async(() => import("../pages/mensagem/Mensagem"));
const ResetPasswordInputSenha = async(() =>
  import("../pages/auth/ResetPasswordInputSenha")
);
const Empresas = async(() => import("../pages/empresa/Empresas"));
const Parametro = async(() => import("../pages/cadastro/parametro/Parametro"));
const Feriado = async(() => import("../pages/cadastro/feriados/Feriado"));
const AlterarSenha = async(() =>
  import("../pages/cadastro/alterarsenha/AlterarSenha")
);
const RelatorioLogUsuario = async(() =>
  import("../pages/relatorio/logusuario/RelatorioLogUsuario")
);
const DetalhesProposta = async(() =>
  import("../pages/proposta/DetalhesProposta")
);
const Notificacao = async(() =>
  import("../pages/cadastro/notificacao/Notificacao")
);
const RelatorioUsuarios = async(() =>
  import("../pages/relatorio/usuarios/RelatorioUsuarios")
);
const Empresa = async(() => import("../pages/cadastro/empresa/Empresa"));
const Perfis = async(() => import("../pages/cadastro/perfis/Perfis"));

// Guards
const AuthGuard = async(() => import("../components/AuthGuard"));

// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));
const SignInCadastro = async(() => import("../pages/auth/SignInCadastro"));

// Dashboards components
const Default = async(() => import("../pages/dashboards/Default"));
const Settings = async(() => import("../pages/manutencao/Settings"));

// Protected routes
const ProtectedPage = async(() => import("../pages/protected/ProtectedPage"));

const suporteRoutes = {
  path: "/",
  header: "Suporte",
  permission: [],
  perfil: ["SUPORTE", "FINANCEIRO"],
  children: [],
  component: null,
  guard: AuthGuard,
};

const empresasRoutes = {
  id: "Empresas",
  path: "/empresas",
  perfil: ["SUPORTE", "FINANCEIRO"],
  icon: <AiOutlineBank />,
  permission: [],
  component: Empresas,
  children: null,
  guard: AuthGuard,
};

const mensagensRoutes = {
  id: "Mensagens",
  path: "/mensagens",
  perfil: ["SUPORTE", "FINANCEIRO"],
  icon: <BiMessageAltCheck />,
  permission: [],
  component: Mensagem,
  children: null,
  guard: AuthGuard,
};

const dashboardsRoutes = {
  id: "Dashboard",
  path: "/dashboard",
  permission: ["visualiza_dashboard_ponto"],
  header: "Operacional",
  icon: <RiDashboardLine />,
  containsHome: true,
  component: Default,
  guard: AuthGuard,
};

const consultasRoutes = {
  id: "Cadastros",
  path: "/cadastros",
  permission: [
    "manutencao_perfis",
    "manter_pontoVenda",
    "manter_tabela",
    "manter_regra_tabela",
    "manter_produto",
    "manter_promotora",
    "manter_parametros",
    "manter_proposta",
    "incluir_pre_digitacao",
    "vincular_lote",
    "manter_antifraude",
    "manter_pessoa",
    "manter_usuario",
    "gerenciamento_eventos_calendario",
    "manter_split"
  ],
  icon: <PlusSquare />,
  guard: AuthGuard,
  children: [
    {
      path: "/cliente/cadastro/:idMutuarioParam",
      hiddenSidebar: true,
      permission: ['manter_pessoa'],
      component: ManterCliente,
      guard: AuthGuard,
    },
    {
      path: "/cliente/cadastro",
      hiddenSidebar: true,
      permission: ['manter_pessoa'],
      component: ManterCliente,
      guard: AuthGuard,
    },
    {
      path: "/usuario/cadastro/:idMutuarioParam",
      hiddenSidebar: true,
      permission: ['manter_usuario'],
      component: ManterUsuario,
      guard: AuthGuard,
    },
    {
      path: "/cliente/proposta/:idProposta",
      hiddenSidebar: true,
      permission: ['manter_proposta', 'incluir_pre_digitacao'],
      component: DetalhesProposta,
      guard: AuthGuard,
    },
    {
      path: "/proposta/cadastro",
      hiddenSidebar: true,
      permission: ['manter_proposta', 'incluir_pre_digitacao'],
      component: ManterProposta,
      guard: AuthGuard,
    },
    {
      path: "/usuario/cadastro",
      hiddenSidebar: true,
      permission: ['manter_usuario'],
      component: ManterUsuario,
      guard: AuthGuard,
    },
    {
      name: "Cliente",
      path: "/cliente/pesquisa",
      permission: ['manter_pessoa'],
      component: PesquisarCliente,
      guard: AuthGuard,
    },
    {
      name: "Usuário",
      path: "/usuario/pesquisa",
      permission: ['manter_usuario'],
      component: PesquisarUsuario,
      guard: AuthGuard,
    },
    {
      path: "/cliente/proposta",
      name: "Proposta",
      permission: ['manter_proposta', 'incluir_pre_digitacao'],
      component: Proposta,
      guard: AuthGuard,
    },
    {
      path: "/alteracaoEmLote",
      name: "Alterações em Lote",
      permission: ["vincular_lote"],
      component: AcoesLote,
      guard: AuthGuard,
    },
    {
      path: "/antifraude",
      name: "Antifraude",
      permission: ["manter_antifraude"],
      component: Antifraude,
      guard: AuthGuard,
    },
    {
      path: "/processamento/cartaocredito",
      name: "Arquivo Cartão Crédito",
      permission: ["geracao_arquivo_cartao"],
      component: GerarArquivoCartao,
      guard: AuthGuard,
    },
   
    {
      path: "/autocontratacao",
      name: "Autocontratação",
      permission: ["manter_branding"],
      component: BrandingConsignado,
      guard: AuthGuard,
    },
    {
      path: "/cadastros/parametros",
      name: "Configurações",
      permission: ["manter_parametros"],
      component: Parametro,
      guard: AuthGuard,
    },
    {
      path: "/cadastros/convenio",
      name: "Convênio",
      permission: ["manter_convenio"],
      component: Convenio,
      guard: AuthGuard,
    },
    {
      path: "/cadastros/feriados",
      name: "Feriados",
      permission: ["gerenciamento_eventos_calendario"],
      component: Feriado,
      guard: AuthGuard,
    },
    {
      path: "/motivos",
      name: "Motivos",
      permission: ["gerenciar_motivos"],
      component: Motivo,
      guard: AuthGuard,
    },
    {
      path: "/cadastros/perfis",
      name: "Perfis",
      permission: ["manutencao_perfis"],
      component: Perfis,
      guard: AuthGuard,
    },  
    {
      path: "/cadastros/pontoVenda",
      name: "Pontos de Venda",
      permission: ["manter_pontoVenda"],
      component: PontoVenda,
      guard: AuthGuard,
    },
    {
      path: "/processamento/retorno",
      name: "Processamento de Retorno",
      permission: ["processamento_arquivo_retorno"],
      component: ImportacaoArquivoRetorno,
      guard: AuthGuard,
    },
    {
      path: "/cadastros/produto",
      name: "Produto",
      permission: ["manter_produto"],
      component: Produto,
      guard: AuthGuard,
    },
    {
      path: "/cadastros/promotora",
      name: "Promotora",
      permission: ["manter_promotora"],
      component: Promotora,
      guard: AuthGuard,
    },
    {
      path: "/cadastros/tabelaRegra",
      name: "Regras de Tabela",
      permission: ["manter_regra_tabela"],
      component: TabelaRegra,
      guard: AuthGuard,
    },
    {
      path: "/cadastros/secretaria",
      name: "Secretaria",
      permission: ["manter_secretaria"],
      component: Secretaria,
      guard: AuthGuard,
    },       
    {
      path: "/cadastros/tabela",
      name: "Tabela",
      permission: ["manter_tabela"],
      component: Tabela,
      guard: AuthGuard,
    },   
    {
      path: "/cadastros/split",
      name: "Split",
      permission: ["manter_split"],
      component: Split,
      guard: AuthGuard,
    },
  ],
  component: null,
};


const pagesRoutes = {
  id: "Relatórios",
  path: "/relatorios",
  permission: [
    "rel_log_usuario",
    "relatorio_usuarios",
    "relatorio_propostas",
    "manter_simulacoes",
    "manter_leads",
    "relatorio_comissionamento_promotora",
    "relatorio_comissionamento_tabela"
  ],
  icon: <HiOutlineDocumentReport />,
  children: [
    
    {
      path: "/relatorios/comissoes",
      name: "Comissões",
      permission: ["relatorio_comissionamento_promotora"],
      component: RelatorioComissao,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/comissoesTabelas",
      name: "Comissões de Tabelas",
      permission: ["relatorio_comissionamento_tabela"],
      component: RelatorioTabelaComissao,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/inadimplencia",
      name: "Inadimplência",
      permission: ["relatorio_inadimplencia"],
      component: RelatorioInadimplencia,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/leads",
      name: "Leads",
      permission: ["manter_leads"],
      component: Leads,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/auditoria",
      name: "Log do Usuário",
      permission: ["rel_log_usuario"],
      component: RelatorioLogUsuario,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/parcelas",
      name: "Parcelas",
      permission: ["relatorio_parcelas"],
      component: RelatorioParcelas,
      guard: AuthGuard,
    },  
    {
      path: "/relatorios/propostas",
      name: "Propostas",
      permission: ["relatorio_propostas"],
      component: RelatorioProposta,
      guard: AuthGuard,
    },  
    {
      path: "/relatorios/simulacoes",
      name: "Simulações",
      permission: ["manter_simulacoes"],
      component: Simulacao,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/usuarios",
      name: "Usuários",
      permission: ["relatorio_usuarios"],
      component: RelatorioUsuarios,
      guard: AuthGuard,
    },
  
   
  ],
  component: null,
};

const empresaRoute = {
  id: "Empresa",
  path: "/empresa",
  permission: [],
  icon: <ShoppingCart />,
  component: Empresa,
  children: null,
  guard: AuthGuard,
};

const authRoutes = {
  id: "Auth",
  path: "/",
  permission: [],
  icon: <Users />,
  children: [
    {
      path: "/",
      name: "Login",
      component: SignIn,
    },
    {
      path: "/auth/posCadastro/:token",
      hiddenSidebar: true,
      component: SignInCadastro,
    },
    {
      path: "/auth/reset-password",
      name: "Esqueci Minha Senha",
      component: ResetPassword,
    },
    {
      path: "/recuperarSenha/:hash",
      name: "Alterar Senha",
      component: ResetPasswordInputSenha,
    },
    {
      path: "/auth/mfa",
      name: "Token MFA",
      component: TokenMFA,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
};

const componentsRoutes = {
  path: "/",
  header: "Produtividade",
  permission: [],
  children: [],
  component: null,
  guard: AuthGuard,
};


const notificacaoRoutes = {
  id: "Notificações",
  path: "/notificacao/manter",
  permission: [],
  icon: <TiMessages />,
  component: Notificacao,
  children: null,
  guard: AuthGuard,
};

const meuPontoRoutes = {
  path: "/",
  header: "Minha Área",
  permission: [],
  children: [],
  component: null,
  guard: AuthGuard,
};

const alterarSenha = {
  id: "Alterar Senha",
  permission: [],
  path: "/alterarSenha",
  icon: <RiLockPasswordLine />,
  component: AlterarSenha,
  children: null,
  guard: AuthGuard,
};

const meuPerfil = {
  id: "Meus Dados",
  permission: [],
  path: "/manutencao/profile",
  icon: <CgProfile />,
  component: Settings,
  children: null,
  guard: AuthGuard,
};

// This route is only visible while signed in
const protectedPageRoutes = {
  id: "Private",
  permission: [],
  path: "/app",
  component: ProtectedPage,
  children: null,
  guard: AuthGuard,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  dashboardsRoutes,
  consultasRoutes,
  
  pagesRoutes,
  suporteRoutes,
  empresasRoutes,
  mensagensRoutes,
  empresaRoute,
  componentsRoutes,
  notificacaoRoutes,
  meuPontoRoutes,
  meuPerfil,
  alterarSenha,
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes that are protected
export const protectedRoutes = [protectedPageRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [
  dashboardsRoutes,
  consultasRoutes,
  pagesRoutes,
  suporteRoutes,
  empresasRoutes,
  mensagensRoutes,
  componentsRoutes,
  notificacaoRoutes,
  meuPontoRoutes,
  meuPerfil,
  alterarSenha,
];
