import { Box, IconButton, Popover } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { MdHelpOutline } from "react-icons/md";

const useStyles = makeStyles((theme) =>
  createStyles({
    typography: {
      padding: theme.spacing(2),
      width: 340,
    },
  })
);

const Help = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <IconButton
        aria-describedby={id}
        onClick={(event) => handleClick(event)}
        size="large">
        <MdHelpOutline />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box boxShadow={3}>
          <Typography className={classes.typography}>
            {props.description}
          </Typography>
        </Box>
      </Popover>
    </div>
  );
};

export default Help;
