const isBlank = (value) => {
  return value === undefined || value === null || value.trim() === "";
};

const firstName = (fullName) => {
  if (isBlank(fullName)) {
    return "";
  }

  var name = fullName.split(" ").slice(0, -1).join(" ");

  if (name === undefined || name === null || name === "") {
    return fullName;
  }

  return name;
};

function preencheZerosEsquerda(value, size) {
  return value.toString().padStart(size, "0");
}

function fetchFromObject(obj, prop) {
  if (typeof obj === "undefined") return false;

  //index of next property split
  var _index = prop.indexOf(".");

  //property split found; recursive call
  if (_index > -1) {
    //get object at property (before split), pass on remainder
    return fetchFromObject(
      obj[prop.substring(0, _index)],
      prop.substr(_index + 1)
    );
  }

  //no split; get property
  return obj[prop];
}

const StringUtil = {
  isBlank,
  firstName,
  preencheZerosEsquerda,
  fetchFromObject,
};

export default StringUtil;
