import AsyncStorage from '@callstack/async-storage';
// import {push} from 'connected-react-router';
import StringUtil from './StringUtil';
import AsyncStorageUtil from './AsyncStorageUtil';
import Alert from '../components/alert/Alert';
import Axios from 'axios';



Axios.interceptors.request.use((config) => {
    return AsyncStorage.getItem('token').then((resposta) => {
        if (!StringUtil.isBlank(resposta)) {
            config.headers.common.Authorization = `Bearer ${resposta}`;
        }
        return Promise.resolve(config);
    }).catch((error) => {
        return Promise.resolve(config);
    });
}, (error) => {
    if (error.response.status === 401) {
        Alert.error('Sessão expirada', 'Sessão expirada, logue novamente');
        AsyncStorage.clear();
        Axios.defaults.headers.common.Authorization = undefined;
        // push('/');
    }
    return Promise.reject(error);
});

Axios.interceptors.response.use((response) => {
    if (response.data.token !== null && response.data.token !== undefined && response.data.token !== "") {
        AsyncStorageUtil.setToken(response.data.token);
        Axios.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;
    }
    return response;
}, (error) => {
    if (error.response === undefined) {
        Alert.error('Erro', 'Tivemos um problema na conexão entre os servidores. Tente novamente mais tarde!');
    }
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        // console.log(history.state);
        // history.push('/');

        window.location.href = '/';
        Alert.error('Sessão expirada', 'Sessão expirada, logue novamente');
        AsyncStorage.clear();
        Axios.defaults.headers.common.Authorization = undefined;
        return Promise.reject(error);
    }

    if (error.response && (error.response.status === 400) && error.response.data !== undefined && error.response.data.message !== undefined) {
        Alert.error('Validação Negocial', error.response.data.message);
        return Promise.reject(error);
    }

    if (error.response && (error.response.status === 500 || error.response.status === 404 )) {
       Alert.error('Erro', 'Caso o erro persista, entre em contato com a administrador do sistema');
    }
    return Promise.reject(error);
});
